import React from "react"
import {
  Aligned,
  Margin,
  RightAlignedImage,
  RightHalfWrapper,
} from "../../utils/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import { Cards } from "./cards"
import { useI18next } from "gatsby-plugin-react-i18next"

const How = () => {
  const { t } = useI18next()
  return (
    <>
      <Aligned id="how">
        <RightHalfWrapper
          data-sal="slide-up"
          data-sal-duration="2000"
          data-sal-easing="ease-out-back"
        >
          <Margin>
            <h1>{t("how.title")}</h1>
            <p>{t("how.subtitle")}</p>
          </Margin>
        </RightHalfWrapper>

        <Cards />

        <RightAlignedImage
          data-sal="slide-up"
          data-sal-duration="2000"
          data-sal-easing="ease-out-back"
        >
          <img
            style={{ height: 245 }}
            src={require(`../../images/orange/undraw_deliveries_131a.svg`)}
            alt={"Questions"}
          />
        </RightAlignedImage>
        <Link to={`/${t("pages.terms")}`}>
          {t("how.read-more")} <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </Aligned>
    </>
  )
}

export default How
