import React from "react"
import Typed from "react-typed"
import { Aligned, RightAlignedImage, RightHalfWrapper } from "../utils/styled"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const What = () => {
  const { t } = useTranslation()

  return (
    <Aligned id="what">
      <RightHalfWrapper>
        <h1 style={{ textOverflow: "visible" }}>
          {t("what.text1")} <br />
          <span style={{ color: `#ff5700` }}>
            <Typed
              strings={["Websites", "Webapps", "Mobile apps", "Software"]}
              typeSpeed={40}
              backSpeed={70}
              loop
            />
          </span>
          <br />
          {t("what.text2")}
          <br />
          {t("what.text3")}
        </h1>
      </RightHalfWrapper>
      <RotatedRightAlignedImage>
        <img
          style={{ height: 300 }}
          src={require(`../images/yellow/undraw_app_wireframe_9ssu.svg`)}
          alt={"What_image"}
        />
      </RotatedRightAlignedImage>
    </Aligned>
  )
}

const RotatedRightAlignedImage = styled(RightAlignedImage)`
  transform: scale(-1, 1);
`

export default What
