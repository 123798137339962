import React from "react"

import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import What from "../components/what"
import Why from "../components/why"
import How from "../components/how/how"
import { Contact } from "../components/contact"
import { ThemeProvider } from "styled-components"
import theme from "../styles/theme"

const IndexPage = () => (
  <>
    <ThemeProvider theme={theme}>
      <Layout gradient>
        <SEO title="Alexandre Ramalho" />
        <What />
        <Why />
        <How />
      </Layout>
      <Contact />
    </ThemeProvider>
  </>
)

export default IndexPage
