import { Flex } from "rebass"
import { RebassCard } from "../../utils/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar, faLaughBeam } from "@fortawesome/free-regular-svg-icons"
import { faEuroSign } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { useTranslation } from "react-i18next"

const sal = {
  mode: "slide-right",
  duration: "300",
  delay: "200",
  easing: "ease-out-back",
}

export const Cards = () => {
  const { t } = useTranslation()

  return (
    <Flex flexWrap="wrap">
      <RebassCard
        sal={sal}
        title={t("how.cards.deadline-card.title")}
        icon={<FontAwesomeIcon icon={faCalendar} />}
      >
        {t("how.cards.deadline-card.text")}
      </RebassCard>
      <RebassCard
        sal={{ ...sal, delay: "250" }}
        title={t("how.cards.money-card.title")}
        icon={<FontAwesomeIcon icon={faEuroSign} />}
      >
        {t("how.cards.money-card.text")}
      </RebassCard>
      <RebassCard
        sal={{ ...sal, delay: "300" }}
        icon={<FontAwesomeIcon icon={faLaughBeam} />}
        title={t("how.cards.happy-card.title")}
      >
        {t("how.cards.happy-card.text")}
      </RebassCard>
    </Flex>
  )
}
