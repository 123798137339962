import React from "react"
import { Aligned, LeftAlignedImage, LeftHalfWrapper } from "../utils/styled"
import { useTranslation } from "react-i18next"

const Why = () => {
  const { t } = useTranslation()
  return (
    <Aligned id="why">
      <LeftHalfWrapper
        data-sal="slide-up"
        data-sal-duration="2000"
        data-sal-easing="ease-out-back"
      >
        <h1>{t("why.title")}</h1>
        <p>{t("why.text")}</p>
      </LeftHalfWrapper>
      <LeftAlignedImage
        data-sal="slide-up"
        data-sal-duration="2000"
        data-sal-easing="ease-out-back"
      >
        <img
          src={require(`../images/yellow/method-draw-image.svg`)}
          alt={"What_image"}
        />
      </LeftAlignedImage>
    </Aligned>
  )
}

export default Why
